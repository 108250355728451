@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

/**
 * VARIABLES
 */
@import '_variables';

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';


// required and to get updates
$cookieconstent-background: #414141 !default;
$cookieconstent-padding: 1.5rem 0 !default;
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
// example or use it
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom";
//@debug@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition";


body, html {
    height: 100%;
}

body {
    font-family: 'Lato', 'Helvetica Neue', 'Helvetica', Arial,sans-serif;
    margin:0;
    padding:0;
    line-height: 1.5;
    font-weight: 400;
    color: #fff;

}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', Arial,sans-serif;
    letter-spacing: 1.75px;
    color: theme-color('secondary');
}

p, ul, ol {
    line-height: 1.5;
    font-size: 1.1rem;
    color: $font-color;
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
}

a {
  cursor: pointer;
}

.bold {
    font-weight: 600
}

ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-wrapper {
    flex: 1 0 auto;
    // max-width: 1375px;
    // width: 90vw;
    margin: 0 auto;
    position: relative;

    .single {
        margin-top: 4rem;
    }

    .container {
        position:relative;

        &.section--large {
            margin-top: 12rem;
            margin-bottom: 12rem;
        }
        &.section--small {
          margin-top: 8rem;
          margin-bottom: 8rem;
        }

        .pagination--wrapper {
            margin-bottom: 4rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

#app-container,
.main {
    background: white;
}

.main {
  display: flex;
  justify-content: center;

  .main--wrapper {
    width: 100vw;
  }
}

// helpers

.flex {
    display: flex
    // flex-direction: row
}

.flex-jc {
    justify-content: center;
}
.flex-ac {
    align-items: center;
}
.flex-end {
    justify-content: flex-end;
    display: flex;
}
.flex-start {
    justify-content: flex-start;
    display: flex;
}
.full {
    width: 100%;
}
.font-bold {
    font-weight: 700;
}

a {
    &:hover {
        //color: #61d3a1;
        color: theme-color('info');
        text-decoration: none;
    }
}

img {
     max-width: 100%;
     width: 100%;
     height: auto;
}

ol.ui.list ol li:before, .ui.ordered.list .list > .item:before {
    margin-left: -3rem;
}
ol.ui.list li, .ui.ordered.list .list > .item, .ui.ordered.list > .item {
    margin-bottom: 2rem;

    h5 {
        margin-bottom: 1rem;
        font-weight: 900;
    }
}

.navbar-brand {
    // padding-top: .5rem;
    padding-bottom: .5rem;
}



.logo-image {
    width: 90%!important;
    margin-left: 10px;
}

.jumbotron {
  display: flex;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 460px;

    &.wrapper__404 {
        position: relative;
        align-items: inherit;
        border-bottom: 2rem solid #4D50A2;

        .image__404 {
            max-height: 300px;
            position: absolute;
            bottom: calc(0px - 4rem);
            width: auto;
        }
    }

}

.btn-row {

    a {
        margin-right: 1.5rem;

        .btn {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .btn {

        &.btn-sm {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }



    }


}

.btn {
    border-radius: 30px;
    font-family: 'LatoLight', 'Helvetica Neueu', 'Helvetica', Arial, sans-serif;

    &.btn-lg {
        padding: 6px 20px;
    }

    &.btn-sm {
        padding: 3px 20px;
    }

    &.btn-primary {
        color: white!important;
        border: 1.5px solid theme-color('primary');
        &:hover {
          background: #1f4075;
        }
    }

    &.btn-highlight {
        color: white;
        border: 1.5px solid theme-color('highlight');
        background: theme-color('highlight');

        &:hover,
        &:active,
        &:focus {
          background: $indigo;
          border-color: $indigo;
          box-shadow: none;

          svg {
            path{
              stroke: #fff;
            }

          }
        }
    }

    &.btn-primary-alt {
        // background: white;
        color: theme-color('primary');
        border: 1.75px solid theme-color('primary');
    }
    &.btn-highlight-alt {
        color: theme-color('highlight');
        border: 1.75px solid #F2746A;

        &:hover,
        &:active,
        &:focus {
          color: white;
          background: $indigo;
          border-color: $indigo;
          box-shadow: none;

          svg {
            path{
              stroke: white
            }

          }
        }
    }

}

.home-section {
    display: flex;
    flex-direction: column;
    margin: 8rem auto 6rem;
    padding: 4rem 180px 0;

}


.callout-section {
    display: flex;
    flex-direction: column;
    margin: 8rem auto 8rem;
    text-align:center;
    align-content: center;

    &.small {
        margin: 6rem auto 4rem;
    }

    &.large {
        margin: 12rem auto 8rem
    }

    .section-subtitle {
        display: block;
        width: 100%;

        @media(max-width: 767px) {
            font-size: 1.75rem;
        }
    }
}

.what-section-header {
    display: inline-block;
    text-align: center;
}

.top-gray-vector {
    background-image: url("/img/marketing/backgrounds/Vector.svg");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position:absolute;
    background-position: right top;
    right: 0;
    top: -8rem;

    &.individual{
        padding: 4rem 0 0;
        display: flex;
        flex-direction: column;
        background-position: bottom;
    }
}





.section-title {
    color: theme-color('highlight');
    text-transform: uppercase;
    font-size: 18px;
}

.section-subtitle {
    //color: theme-color('gray-dark');
    font-weight: 600;
    color: #1f4075;
}

.jumbotron {
    //height: 80vh;
    min-height: 600px;
    margin-bottom: 0;
    border-radius: 0;
    background-color: #fff;

    @media(max-width: 991px) {
        align-items: flex-start;
    }


    .content-wrapper {
        flex: auto;


        .container {
            margin-right: 0;

            @media(max-width: 991px) {
                margin: 0
            }
        }
    }


    h1 {
        margin-bottom: .25rem;
        font-size: 3.5rem;

        @media(max-width: 991px) {
            font-size: 2.5rem;
        }
    }

    .btn-row {
        margin-top: 2.25rem;

        @media (max-width: 767.98px) {
            a {
                margin: 0;
            }

        }

        button {
            &:first-child {
                margin-left: 0;
            }

            &.btn-highlight {
              color: white;
              border: 1.5px solid theme-color('highlight');
              background: theme-color('highlight');

              &:hover,
              &:active,
              &:focus {
                background: $indigo;
                border-color: $indigo;

                svg {
                  path{
                    stroke: #fff;
                  }

                }
              }
            }
        }
    }
}


/* blog content */
.blog-jumbotron {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e2e2e2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 460px;
    max-height: 460px;

    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 70%;

    }

    h1, h4 {
      margin: 15px auto;
      font-weight: 700;
      color: white;
    }

}

.documentation {
    margin-bottom: 6rem;
    ol, ul {
        margin: 2rem 0;
        li {
            line-height: 1.5;
            font-size: 1.1rem;
            color: #72808E;
            font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
            font-weight: normal;
        }
    }

    pre {
        background: #efefef;
        font-size: .8rem;
        padding: 1rem 1rem .8rem;
        font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
        white-space: pre-wrap;
    }

    table {
        margin: 2rem 0;
        border-collapse: collapse !important;
        border-spacing: 0 1em !important;

        tr {
            border-radius: 0 !important;
            cursor: inherit !important;
        }
        td {
            border-radius: 0 !important;
            cursor: inherit !important;

        }

        &.table-bordered thead th,
        &.table-bordered thead td {
            border-bottom: none !important
        }



    }




}

.blog-content {
    background: white;
    position: relative;
    padding: 60px;

    .blog-meta {
        margin-bottom: 3rem;

        .title {
            font-size: 3.5rem;
        }

        .date {
            font-size: 1rem;
            color: #bbb;
        }
    }

    .sections {

        .featured-image {
            margin-bottom: 3rem;
        }

        position: relative;

        p,
        blockquote,
        ul,
        ol {
            font-weight: 400;
            line-height: 2;
            font-size: 1.25rem;
            color: #72808E;
        }

        .blockquote {
            position: relative;
            padding-left: 1em;
            border-left: 0.2em solid #1f4075;
            font-weight: 100;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }


        a {
            color:#F2746A;
            font-weight: 600
        }

        .headline,
        .paragraph,
        .figure {
            margin-bottom: 2rem;
        }

        .figure {
            text-align: center;
            width: 100%;

            .figure-img {
                width: auto;
            }
        }

        &.references {
            p {
                line-height: 1.5;
                font-size: 1rem;
            }
            h3 {
                margin-bottom: 2rem;
            }

            hr {
                color: #727272;
                font-size: 2.5rem;
                margin: 4rem 0;
                text-align: center;
                font-weight: 300;
                border: none;

                &::before {
                    line-height: 1.4;
                    font-style: italic;
                    letter-spacing: .6rem;
                    content: "...";
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 0;
                }
            }
        }

    }


    .body-header {
        padding: 15px 25px 0;
    }

    .body-text {
        padding: 0 35px 0;
    }


}




/* FOOTER */

footer {
  width: 100%;

  .footer-links {
    margin: 0 20px;
    padding-top: 3rem;
  }

  .footer-links > ul {
    text-align: left;

    img, li {
      margin: 3px 0;
    }
    img {
      margin-bottom: 5px;
    }
  }

  .footer-title {
    font-size: 20px;
    font-weight: 600;
    color: #1f4075;

  }

  .lower-footer {
    align-items: center;
    border-top: 1px solid #EDEDED;
    color: theme-color('secondary');
    font-weight: 600;
  }

  .sup {
    margin: 2rem 0 1rem;
    background: white;

    .d-flex {
      // flex-direction: row;
      justify-content: space-between;
      margin-bottom: 40px;
      // text-align: center;
      // align-items: center;

      .copyright {
        justify-content: flex-start;
        // text-align: left;

        p {
          font-size: 0.85rem;
          font-family: "LatoLight", "Helvetica Neueu", "Helvetica", Arial,
            sans-serif;
          // margin-bottom: 0;
        }
      }

      .navbar-nav {
        flex-direction: row;
        margin: 0;
        font-size: 0.85rem;

        .nav--link {
          color: $font-color;
          margin: 0 8px;

        }
      }

      .social {
        ul {
          list-style: none;
          display: flex;
          margin: 20px 0;

          @media (max-width: 767.98px) {
            justify-content: center;
          }

          li {
            margin: 0 8px;
            font-size: 1.25rem;
            list-style: none;

            &:first-child {
                margin-left: 0;
            }
          }
        }
      }

      @media (max-width: 767.98px) {
        .copyright,
        .sup-nav,
        .social {
          justify-content: center;
          text-align: center;
          margin: 0 auto;
        }
        .copyright {
          p {
            margin: 5px auto 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 48rem) {
    .cookie-consent {
      width: 100%;
      display: flex;

      p {
        margin-bottom: 0;
        font-size: 1rem;
      }
    }
    .Cookie--dark-lime {
      padding: 2rem;
    }
  }
}



